import React, { useState, useEffect, useRef } from "react";
import "../assets/css/Campaigns.css";
import "../assets/css/Vox.css";
import Sidebar from "../components/sidebar";
import ScriptDetail from "../components/scriptDetail";
import config from "../config";
import "../assets/css/Stat.css";
import "../assets/css/Phone-section.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { useUser } from "../context/UserContext";

function ContactCall({ contentMinimized, data, onClose }) {
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [callStatus, setCallStatus] = useState("");
  const [updateContactformErrors, setupdateContactformErrors] = useState({});
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();
  const isMountedRef = useRef(true);
  const timeoutRef = useRef(null);
  const [updateContactData, setUpdateContactData] = useState({
    first_name: data?.contact?.first_name || "",
    last_name: data?.contact?.last_name || "",
    contact_primary: data?.contact?.contact_primary,
    company: data?.contact?.company,
    street: data?.contact?.street || "",
    city: data?.contact?.city || "",
    state: data?.contact?.state || "",
    country: data?.contact?.country || "",
    remarks: "",
    remarksTitle: "",
    contact_id: data?.contact?.id,
  });
  const { user } = useUser();

  const fetchNotes = async (contactId) => {
    setNotes([]);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/v1/reports/${contactId}/notes?limit=5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();

      if (result.success) {
        setNotes(result.data);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    fetchNotes(data?.contact?.id);
  }, []);       
  

  function getPlainText(remarks) {
    try {
      const parsedRemarks = JSON.parse(remarks);

      if (parsedRemarks.blocks && Array.isArray(parsedRemarks.blocks)) {
        return parsedRemarks.blocks.map((block) => block.text).join(" ");
      }
    } catch (e) {}

    return remarks;
  }

  const changeContactValue = (e) => {
    const { name, value } = e.target;
    setUpdateContactData({ ...updateContactData, [name]: value });
    setupdateContactformErrors({ ...updateContactformErrors, [name]: "" });
  };

  const validateUpdateContactFields = () => {
    const errors = {};
    // if (!updateContactData?.first_name) {
    //   errors.first_name = "First name is required";
    // }
    // if (!updateContactData?.last_name) {
    //   errors.last_name = "Last name is required";
    // }
    if (!updateContactData?.contact_primary) {
      errors.contact_primary = "Contact number is required";
    }
    // if (!updateContactData?.remarks) {
    //   errors.remarks = "Remarks is required";
    // }
    // if (!updateContactData?.remarksTitle) {
    //   errors.remarksTitle = "Remarks title is required";
    // }
    // if (!updateContactData?.company) {
    //   errors.company = "Company is required";
    // }
    // if (!updateContactData?.state) {
    //   errors.state = "State is required";
    // }
    // if (!updateContactData?.street) {
    //   errors.street = "Street is required";
    // }
    // if (!updateContactData?.city) {
    //   errors.city = "City is required";
    // }
    // if (!updateContactData?.country) {
    //   errors.country = "Country is required";
    // }
    setupdateContactformErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateContact = async (e, status = false) => {
    if (loading) {
      return;
    }

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!validateUpdateContactFields()) {
      return;
    }

    const contactNumberList = [
      {
        value: updateContactData.contact_primary,
        type: "1",
      },
    ];

    const dataToSend = {
      first_name: updateContactData.first_name,
      last_name: updateContactData.last_name,
      contact_number: contactNumberList,
      company: updateContactData.company,
      street: updateContactData.street,
      city: updateContactData.city,
      state: updateContactData.state,
      country: updateContactData.country,
      remarks: updateContactData.remarks,
      title: updateContactData.remarksTitle,
      created_by: user.id
    };

     // Filter out any keys with value undefined, null, or empty string
    const cleanedData = Object.entries(dataToSend).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    const token = localStorage.getItem("token");

    setLoading(true);

    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/v1/contacts/${updateContactData.contact_primary}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(cleanedData),
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success(data.message || "Contact updated successfully");
        // setUpdateContactData({});
        setupdateContactformErrors({});
        fetchNotes(updateContactData.contact_id);
      } else {
        toast.error(data.message || "Failed to updated contact");
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      toast.error("Error updating contact");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const fetchCallStatus = async (campaign_id, call_id) => {
    try {
      //   if (loading) {
      //     return;
      //   }
      if (!isMountedRef.current) return;
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${config.apiBaseUrl}/api/v1/campaigns/${campaign_id}/calls/${call_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        if (Object.keys(data.data).length === 0) {
          timeoutRef.current = setTimeout(() => {
            if (isMountedRef.current) fetchCallStatus(campaign_id, call_id);
          }, 15000);
        } else {
          setCallStatus(data.data.call_event);
          if (
            data.data.call_event !== "LEAD_CALL_HANGUP" &&
            data.data.call_event !== "AGENT_CALL_HANGUP"
          ) {
            fetchCallStatus(campaign_id, call_id);
          }
        }
      } else {
        toast.error(data.message || "Failed to retrieve call status");
      }
    } catch (error) {
      console.error("Error fetching call status:", error);
      toast.error("Error fetching call status");
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <FadeLoader
            color="#808080"
            height={10}
            width={3}
            radius={0}
            margin={-5}
          />
        </div>
      )}
      <div
        className="modal-overlay"
        style={{
          zIndex: 999999,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflowY: "auto",
        }}
      >
        <div className="modal-xl modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content" style={{ backgroundColor: "#fcfcfc" }}>
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
            >
              <h5 className="modal-title" style={{ margin: 0 }}>
                Contact Details
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "1.5rem",
                  lineHeight: "1",
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="lyt_box mt-4">
                <div className="row g-4">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="block-widget">
                      <h6>Form</h6>
                      <form onSubmit={(e) => handleUpdateContact(e, false)}>
                        <div className="form_group">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            value={updateContactData.first_name || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.first_name && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.first_name}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            value={updateContactData.last_name || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.last_name && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.last_name}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label"> Contact Number*</label>
                          <input
                            type="number"
                            className="form-control"
                            name="contact_primary"
                            value={updateContactData.contact_primary || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.contact_primary && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.contact_primary}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Company</label>
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            value={updateContactData.company || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.company && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.company}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Street</label>
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            value={updateContactData.street || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.street && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.street}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={updateContactData.city || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.city && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.city}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">State</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={updateContactData.state || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.state && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.state}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={updateContactData.country || ""}
                            onChange={changeContactValue}
                          />
                          {updateContactformErrors.country && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.country}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Remarks Title</label>
                          <input
                            className="form-control"
                            name="remarksTitle"
                            value={updateContactData.remarksTitle || ""}
                            onChange={changeContactValue}
                            maxLength={250}
                          />
                          {updateContactformErrors.remarksTitle && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.remarksTitle}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form_group">
                          <label className="form-label">Remarks</label>
                          <textarea
                            className="form-control"
                            name="remarks"
                            value={updateContactData.remarks || ""}
                            onChange={changeContactValue}
                            rows={4}
                            style={{ height: "70px" }}
                          />
                          {updateContactformErrors.remarks && (
                            <div className="error">
                              <span className="text-danger">
                                {updateContactformErrors.remarks}
                              </span>
                            </div>
                          )}
                        </div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="block-widget">
                      <ScriptDetail
                        scriptData={{
                          script_name: campaignData?.scripts?.script_name,
                          short_desc: campaignData?.scripts?.short_desc,
                          script_text: campaignData?.scripts?.script_texts,
                        }}
                      ></ScriptDetail>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="block-widget">
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                        <table
                          className="table table-striped"
                          style={{ width: "100%", tableLayout: "fixed" }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "33%" }}>Agent name</th>
                              <th style={{ width: "33%" }}>Remark</th>
                              <th style={{ width: "33%" }}>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(notes) && notes.length > 0 ? (
                              notes.map((note, index) => (
                                <tr key={note.id}>
                                  <td
                                    style={{
                                      width: "33%",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {note["UserDetail.Agents.agent_name"] || ""}
                                  </td>
                                  <td
                                    style={{
                                      width: "33%",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {getPlainText(note.remarks)}
                                  </td>
                                  <td
                                    style={{
                                      width: "33%",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {note.created_at}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3" className="text-center">
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <h2>Phone</h2>
                      <div className="phone-block mt-3">
                        <div id="output">
                          <div id="incoming-number"></div>
                          <input type="text" id="numberToCall" />
                        </div>
                        <div className="number-flex">
                          <div className="digit box" id="one">
                            <button className="keypad not-visible">1</button>
                          </div>
                          <div className="digit box" id="two">
                            <button className="keypad not-visible">2</button>
                            <div className="sub">ABC</div>
                          </div>
                          <div className="digit box" id="three">
                            <button className="keypad not-visible">3</button>
                            <div className="sub">DEF</div>
                          </div>
                        </div>
                        <div className="number-flex">
                          <div className="digit box" id="four">
                            <button className="keypad not-visible">4</button>
                            <div className="sub">GHI</div>
                          </div>
                          <div className="digit box" id="five">
                            <button className="keypad not-visible">5</button>
                            <div className="sub">JKL</div>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">6</button>
                            <div className="sub">MNO</div>
                          </div>
                        </div>
                        <div className="number-flex">
                          <div className="digit box">
                            <button className="keypad not-visible">7</button>
                            <div className="sub">PQRS</div>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">8</button>
                            <div className="sub">TUV</div>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">9</button>
                            <div className="sub">WXYZ</div>
                          </div>
                        </div>
                        <div className="number-flex">
                          <div className="digit box">
                            <button className="keypad not-visible">*</button>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">0</button>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">#</button>
                          </div>
                        </div>
                        <div className="number-flex">
                          <div className="digit box hold">
                            <input
                              type="checkbox"
                              className="not-visible"
                              id="hold"
                            />
                            <i className="icon-pause"></i>
                          </div>
                          <div className="digit box">
                            <button className="keypad not-visible">+</button>
                          </div>
                          <div className="digit box mute">
                            <input
                              type="checkbox"
                              className="not-visible"
                              id="mute"
                            />
                            <i className="icon-mic"></i>
                          </div>
                        </div>
                        <div className="botrow">
                          <div className="call reject" id="hangup" disabled>
                            <button className="not-visible">
                              <i className="icon-phone" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div className="call answer" id="call">
                            <button className="not-visible">
                              <i className="icon-phone" aria-hidden="true"></i>
                            </button>
                          </div>

                          <i
                            className="icon-arrow-left backspace"
                            aria-hidden="true"
                          >
                            {" "}
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-end my-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleUpdateContact(null, true)}
                    disabled={loading}
                  >
                    Submit and Call next
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactCall;
