import React, { useState } from "react";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import config from "../config";
import { useUser } from "../context/UserContext";
import "../assets/css/ChangePasswordModal.css";

function ChangePasswordModal({onClose, onSuccess}) {

    const { user } = useUser();
    const initialValues ={
        current_password: "",
        new_password: "",
        confirm_password: ""
    }
    const [formData, setFormData] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateField = (data) => {
        const errors = {};
        if (data.current_password === "") errors.current_password = "Current password is required";
        if (!data.new_password) errors.new_password = "New Password is required";
        if (!data.confirm_password) errors.confirm_password = "Confirm Password is required";
        if (data.new_password && data.confirm_password && data.new_password !== data.confirm_password) {
            errors.confirm_password = "Passwords do not match";
        }
        if (data.new_password && data.new_password.length < 6) {
            errors.new_password = 'Password must be at least 6 characters long';
        }
        if (data.confirm_password && data.confirm_password.length < 6) {
            errors.confirm_password = 'Password must be at least 6 characters long';
        }     
        return errors;
    };

    const handleDiscard = () => {
        setFormData(initialValues);
        setFormErrors({});
        onClose()
    };

    const handleSubmit = (e) => {

        e.preventDefault();
    
        const errors = validateField(formData);
        if (Object.keys(errors).length > 0) {
          setFormErrors(errors);
          toast.error("Please fill in all required fields correctly");
          return;
        }
        
        handleUpdateChanges();

    };

    const handleUpdateChanges = () => {
        setIsSaving(true);
        try {
            const token = localStorage.getItem("token");
            const dataToSend = new FormData();

            dataToSend.append('old_password', formData.current_password);
            dataToSend.append('password', formData.new_password);
            
            fetch(`${config.apiBaseUrl}/api/v1/users/${user.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: dataToSend,
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    onSuccess();
                    toast.success( 'Password updated successfully', { containerId: 'sidebarToast' });
                } else {
                    toast.error(`Error updating password: ${data?.error?.message}`, { containerId: 'sidebarToast' });
                }
            })
            .catch(error => console.error('Error updating password:', error));

        } catch (error) {
            console.error('Error updating password:', error)
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            {isSaving && (
                <div className="loader-overlay">
                <FadeLoader
                    color="#808080"
                    height={10}
                    width={3}
                    radius={0}
                    margin={-5}
                />
                </div>
            )}
            <div className="modal" id="ChangeUserPasswordModal" style={{display: "block", background: "rgba(0, 0, 0, 0.5)"}}>
                <div className="modal-dialog modal-md">
                    <form id="change_password">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Change password</h4>
                                <button type="button" className="close" onClick={handleDiscard}>×</button>
                            </div>

                            <div className="modal-body">
                                <div className="form_group ">
                                    <label className="form-label">Current password</label>
                                    <input
                                        required
                                        type="password"
                                        placeholder="Enter your current password"
                                        className="form-control"
                                        name="current_password"
                                        value={formData.current_password}
                                        onChange={handleChange}
                                    />
                                    {formErrors.current_password && <span className="text-danger" style={{ marginLeft: '10px' }}>{formErrors.current_password}</span>}
                                </div>

                                <div className="form_group">
                                    <label className="form-label"> New password </label>
                                    <input
                                        required
                                        type="password"
                                        placeholder="Enter new Password"
                                        className="form-control"
                                        name="new_password"
                                        value={formData.new_password}
                                        onChange={handleChange}
                                    />
                                    {formErrors.new_password && <span className="text-danger" style={{ marginLeft: '10px' }}>{formErrors.new_password}</span>}
                                </div>

                                <div className="form_group m-0">
                                    <label className="form-label">Confirm password </label>
                                    <input
                                        required
                                        type="password"
                                        placeholder="Confirm new password"
                                        className="form-control"
                                        name="confirm_password"
                                        value={formData.confirm_password}
                                        onChange={handleChange}
                                    />
                                    {formErrors.confirm_password && <span className="text-danger" style={{ marginLeft: '10px' }}>{formErrors.confirm_password}</span>}
                                </div>
                            </div>

                            <div className="modal-footer changePassword-modal-footer">
                                <button type="submit" className="btn btn-primary" disabled={isSaving} onClick={handleSubmit}>Change password </button>
                                <button type="button" className="btn-canel btn-cancel-outline-primary" data-dismiss="modal" onClick={handleDiscard}>Cancel</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangePasswordModal;