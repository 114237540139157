import React, { useState, useEffect, useRef } from "react";
import config from "../config";
import Clock from 'react-live-clock';
import logo from "../assets/images/leo2.jpg";
import "../assets/css/style.css";
import { useNavigate } from "react-router-dom";

const Header = ({setIsReady,setIsOnBreak, setLoginStatus }) => {

  const [headerData, setHeaderData] = useState({});
  const timeZone = process.env.TIME_ZONE || 'Asia/Kathmandu';
  const navigate = useNavigate();
  const lastFetchTimeRef = useRef(new Date().getTime());

  const fetchHeaderData = async () => {
    const currentTime = new Date().getTime();
    lastFetchTimeRef.current = currentTime;
    let user_stat_expiry = localStorage.getItem("user_stat_expiry");
    let cachedHeaderData = localStorage.getItem("headerData");

    if (cachedHeaderData) {
      setHeaderData(JSON.parse(cachedHeaderData));
    }

    if (user_stat_expiry && parseInt(user_stat_expiry, 10) > currentTime) {
      return;
    } else {
      
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/v1/dashboard/stats`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const result = await response.json();

        if (result.success) {
          localStorage.setItem("headerData", JSON.stringify(result.data.headerData));
          localStorage.setItem('isReady', result.data.user_status.isReady);
          localStorage.setItem('isReadyForCall',  result.data.user_status.isReady);
          localStorage.setItem('login_status',  result.data.user_status.login_status);
          localStorage.setItem('is_on_break',  result.data.user_status.is_on_break);
          setIsReady(result.data.user_status.isReady);
          setIsOnBreak(result.data.user_status.is_on_break);
          setLoginStatus(result.data.user_status.login_status);
          localStorage.setItem('queue_names', result.data.current_user_data.queue_names);
          localStorage.setItem('shift_names', result.data.current_user_data.shift_names);
          localStorage.setItem('agent_id',  result.data.current_user_data?.agent_id);
          if(result.data.user_status.is_user_logged_out === 1) {
            localStorage.clear();
            navigate("/login");
          }
          if(result.data.login_status !== undefined) {
            localStorage.setItem("login_status", JSON.stringify(result.data.login_status));
          }
          setHeaderData(result.data.headerData);
        }
        localStorage.setItem("user_stat_expiry", currentTime + 30000);
      } catch (error) {
        console.error("Error fetching header data:", error);
      }
    }  
  };

  useEffect(() => {
    fetchHeaderData();

    // Check if the function was called in the last 30 seconds
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime - lastFetchTimeRef.current > 30000) {
        fetchHeaderData();
      }
    }, 1000);

    return () => clearInterval(interval);

  }, []);

  return (
    <div>
      <div className="logo-header" style={{ marginTop: "-1px" }}>
        <img src={logo} alt="" className="logo" />
      </div>

      {/* Time */}
      <div className="gd_insightsBar insights_bar">
        {/* Time */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-clock clock-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="ct" className="status_bar_left" />
            <p id="clock"><Clock format={'hh:mm:ss A'} ticking={true} timezone={timeZone} /></p>
          </div>
        </div>
        {/* service level */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-chart-donut donut-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_service_level">{headerData?.SLE || 0}%</p>
            <p>SLE</p>
          </div>
        </div>
        {/* total calls */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-phone phone-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_total_calls">{headerData?.total_calls || 0}</p>
            <p>Total calls</p>
          </div>
        </div>
        {/* calls made */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-phone-outgoing outgoing-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_calls_made">{headerData?.calls_made || 0}</p>
            <p>Calls made</p>
          </div>
        </div>
        {/* calls received */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-phone-incoming incoming-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_calls_received">{headerData?.calls_received || 0}</p>
            <p>Calls received</p>
          </div>
        </div>
        {/* oncall */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-phone-volume  volume-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_oncall">{headerData?.on_call || "00:00:00"}</p>
            <p>On call</p>
          </div>
        </div>
        {/* break */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-coffee coffee-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_break_duration">{headerData?.user_break_duration || "00:00:00"}</p>
            <p>Break duration</p>
          </div>
        </div>
        {/* idle */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-check-circle circle-icon" />
          </div>
          <div className="insights_excerpt">
            <p id="header_idle_time">{headerData?.user_logged_duration || "00:00:00"}</p>
            <p>Logged duration</p>
          </div>
        </div>
        {/* idle */}
        {/* network connection */}
        <div className="insights_item">
          <div className="insights_media">
            <i className="icon-network network-icon" />
          </div>
          <div className="insights_excerpt noborder">
            <p id="header_network_status">-</p>
            <p>Network</p>
          </div>
        </div>
        {/* webphone */}
      </div>
    </div>
  );
};
export default Header;
