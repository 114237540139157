import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const fullName = localStorage.getItem('full_name');
  const profilePic = localStorage.getItem('profile_pic');
  const email = localStorage.getItem('email');
  const mobileNumber = localStorage.getItem('mobile_number');
  const id = localStorage.getItem('user_id');
  const assignedQueue = localStorage.getItem('queue_names');
  const shift = localStorage.getItem('shift_names');
  const lastlogout = localStorage.getItem('last_logout_at');

  if (token) 
  {
    var UserObject ={token, role, fullName, profilePic, email, mobileNumber, id, assignedQueue, shift, lastlogout};
    
  // setUser({ token, role, fullName, profilePic, email, mobileNumber, id });
  }

  return (
    <UserContext.Provider value={{ user:UserObject, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
