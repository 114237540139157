export const routeRestrictions = {
  SUPERVISOR: [
  
  ],
  SUPERADMIN: [],
  AGENT: [
    "/settings",
    "/distributionReport",
    "/teamReport",
    "/agentDetail",
    "/Realtime",
    "/callreport",
    "/queueDetail",
    "/agentReport",
    "/trunkReport",
    "/incomingDetail",
    "/outboundDetail",
    "/teamReport",
    "/queueDetail",
    "/distributionReport",
    "/additionalData",
    "/getCallStatus",
    "/stats"
  ],
};
