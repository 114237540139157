import React, { useState } from "react";
import "../assets/css/Sidebar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Header from "./header";
import "../assets/css/header.css";
import userImage from "../assets/images/user-img.jpg";
import { useUser } from "../context/UserContext";
import SignOut from "./SignOut";
import UserBreak from "./userBreak";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ChangePasswordModal from './changePasswordModal';
import config from "../config";
import { USERS_ROLE, LOGIN_STATUS, READY_STATUS } from "../utils/common/constants";


function Sidebar({ Minimized }) {
  const [isMenuItemExpanded, setMenuItemExpanded] = useState(false);
  const [isReportExpanded, setReportExpanded] = useState(false);
  const [isWallboardExpanded, setWallboardExpanded] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSignOutModalVisible, setSignOutModalVisible] = useState(false);
  const [isBreakModalVisible, setBreakModalVisible] = useState(false);
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [loginStatus, setLoginStatus] = useState(parseInt(localStorage.getItem("login_status"), 10) ?? "");
  const [isReady, setIsReady] = useState(parseInt(localStorage.getItem("isReady"), 10) ?? false);
  const [isOnBreak, setIsOnBreak] = useState(parseInt(localStorage.getItem("is_on_break"), 10) ?? false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedQueue, setAssignedQueue] = useState((localStorage.getItem("queue_names")) ?? "");
  const [shift, setShifts] = useState((localStorage.getItem("shift_names")) ?? "");

  const { user } = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleMenuItemClick = () => {
    setMenuItemExpanded(!isMenuItemExpanded);
  };

  const handleReportClick = () => {
    setReportExpanded(!isReportExpanded);
  };

  const handlWallboardClick = () => {
    setWallboardExpanded(!isWallboardExpanded);
  };

  const toggleDropdown = () => {
    const login_status = localStorage.getItem("login_status");
    const isReady = localStorage.getItem("isReady");
    const isOnBreak = localStorage.getItem("is_on_break");
    const parsedStatus = parseInt(login_status, 10);
    const parsedIsReady = parseInt(isReady, 10);
    const parsedIsOnBreak = parseInt(isOnBreak, 10);
    setLoginStatus(isNaN(parsedStatus) ? '' : parsedStatus);
    setIsReady(isNaN(parsedIsReady) ? '' : parsedIsReady);
    setIsOnBreak(isNaN(parsedIsOnBreak) ? '' : parsedIsOnBreak);
    setDropdownVisible((prevState) => !prevState);
    setAssignedQueue(localStorage.getItem("queue_names"));
    setShifts(localStorage.getItem("shift_names"));
  };

  const handleSignOut = () => {
    setSignOutModalVisible(true);
    // localStorage.clear();
    // navigate("/login");
  };

  const handleSignOutModalClose = () => {
    setSignOutModalVisible(false);
  };

  const handleAccountClick = () => {
    navigate("/myaccount");
  };

  const updateStatus = () => {
    if (isLoading) return;
    if (isReady) {
      setBreakModalVisible(true);
    } else if (isOnBreak) {
      removeUserBreak();
    } else {
      handleStatusChangeClick();
    }
  };

  const removeUserBreak = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/v1/users/${user.id}/break`,
        {
          op: "remove",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Break removed successful!", { containerId: 'sidebarToast' });
        setIsReady(READY_STATUS.ACTIVE);
        setIsOnBreak(0);
        setLoginStatus(LOGIN_STATUS.ACTIVE);
        localStorage.setItem('isReady', READY_STATUS.ACTIVE);
        localStorage.setItem('is_on_break', 0);
        localStorage.setItem('isReadyForCall', READY_STATUS.ACTIVE);
        localStorage.setItem('login_status', LOGIN_STATUS.ACTIVE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error while removing break: ${error.response?.data?.message || error.message}`, { containerId: 'sidebarToast' });
      } else {
        toast.error("Error while removing break: " + error.message, { containerId: 'sidebarToast' });
      }
      console.error("Error removing break:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBreakSuccess = () => {
    setLoginStatus(LOGIN_STATUS.INACTIVE);
    setIsOnBreak(1);
    setIsReady(READY_STATUS.INACTIVE);
    localStorage.setItem('login_status', LOGIN_STATUS.INACTIVE);
    localStorage.setItem('isReadyForCall', READY_STATUS.INACTIVE);
    localStorage.setItem('isReady', READY_STATUS.INACTIVE);
    localStorage.setItem('is_on_break', 1);
    setBreakModalVisible(false);
  };

  const handleStatusChangeClick = async () => {
    console.log("CAME TO HANDLE STATUS CLICK");
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token"); // Assuming JWT is stored in localStorage with key 'jwt'
      const response = await axios.put(
        `${config.apiBaseUrl}/api/v1/users/${user.id}/status`,
        {
          type: "ready", // Sending the type as 'ready' in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "RESPONSE HERE");
      if (response.data.success) {
        console.log(response.data.message, "RESPONSE MESSAGE");
        setLoginStatus(LOGIN_STATUS.ACTIVE);
        setIsReady(READY_STATUS.ACTIVE);
        localStorage.setItem('isReady', READY_STATUS.ACTIVE);
        localStorage.setItem('isReadyForCall', READY_STATUS.ACTIVE);
        localStorage.setItem('login_status', LOGIN_STATUS.ACTIVE);
        toast.success(response.data.message, { containerId: 'sidebarToast' });
      } else {
        toast.error(response.data.message, { containerId: 'sidebarToast' });
      }
    } catch (error) {
      toast.error("Failed to change status", { containerId: 'sidebarToast' }); // Display error toast if the request fails
      console.error("Error changing status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return null; // or a loading spinner, or any other placeholder
  }

  const { fullName, role, profilePic, email, lastlogout } = user;

  return (
    <>
      <div>
        <ToastContainer containerId="sidebarToast" />
        {isLoading && (
          <div className="loader-overlay">
            <FadeLoader color="#808080" height={10} width={3} radius={0} margin={-5} />
          </div>
        )}
        <section className="content">
          <div
            style={{
              display: "flex",
              paddingTop: "10px",
              justifyContent: "space-between",
              borderBottom: "1px solid #B0B0B0",
              backgroundColor: "#FFFFFF",
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "65px",
              zIndex: "1",
            }}
          >
            <div style={{ marginLeft: "-90px" }}>
              <Header  setIsReady={setIsReady} setIsOnBreak={setIsOnBreak} setLoginStatus ={setLoginStatus}/>
            </div>
            <div
              className="user-account-wrapper"
              style={{ position: "relative" }}
            >
              <div
                className="user-account"
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              >
                <div className="user_media">
                  <img
                    src={profilePic || userImage}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = userImage;
                    }}
                    alt="profile pic"
                    className="profile-pic"
                  />
                  <span className="header_status" id="dnd_slider_text">
                    {loginStatus === LOGIN_STATUS.ACTIVE && isReady === READY_STATUS.ACTIVE ? (
                      <i
                        title="Ready"
                        className="fa fa-check-circle green" style={{ color: "#17B169" }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        title="Not ready"
                        className="fa fa-times-circle red" style={{ color: "#E5554E" }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </span>
                </div>
                <div className="user_excerpt">
                  <div className="user_name">{fullName}</div>
                  <div className="user_info">
                    <span id="u_role">{role}</span>
                  </div>
                </div>
              </div>
              <div
                className={`dropdown-menu dropdown-menu-right dropdown_account ${isDropdownVisible ? "show" : ""
                  }`}
                style={{ position: "absolute", top: "100%", right: "0" }}
              >
                <div className="dropdown_group">
                  <div className="profile profile_md">
                    <div className="profile_media ">
                      <img
                        src={profilePic || userImage}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = userImage;
                        }}
                        alt="profile pic"
                        className="profile-pic"
                      />
                    </div>
                    <div className="profile_excerpt">
                      <h5>
                        <b>{fullName ?? ''}</b>
                      </h5>
                      <div className="d-flex align-items-center">
                        <span title="role">{role ?? ''}</span>
                      </div>
                      <div>
                        <span className="ellipsis_h" title="email">
                          {email ?? ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown_group px-0">
                  <div className="dropdown_group__body">
                    {role !== USERS_ROLE.SUPERADMIN &&
                      <span id="user_status_span">
                        <a className="dropdown_link" href="#"
                          onClick={updateStatus}
                        >
                          <i className="fa-regular fa-user"></i>
                          <span>{isReady === READY_STATUS.ACTIVE ? "Set Break" : (isOnBreak === 1 ? "Remove Break" : "Ready")}</span>
                        </a>
                      </span>
                    }
                    <Link to="/myaccount" onClick={handleAccountClick}>
                      <span className="dropdown_link">
                        <i className="fa-regular fa-user"></i>
                        <span>My account</span>
                      </span>
                    </Link>
                    <a
                      className="dropdown_link drop_actions_divs password_edit ajaxedituserpasswordbutton"
                     onClick={()=> {
                      setChangePasswordModalVisible(true);
                      setDropdownVisible((prevState) => !prevState);
                    }}
                     style={{ cursor: "pointer" }}
                    >
                      <i className="fa-solid fa-key outlined-key"></i>
                      <span>Change password</span>
                    </a>
                    <a
                      className="dropdown_link"
                      href="#"
                      onClick={handleSignOut}
                    >
                      <i className="fa-solid fa-sign-out-alt outlined-icon"></i>
                      <span>Sign Out</span>
                    </a>
                  </div>
                </div>
                <div className="dropdown_group">
                  <ul className="list_info list_sm">
                    <li className="list_item">
                      <span className="index">Assigned Queue:</span>
                      <span className="value apurple">
                        <b>{assignedQueue || 'N/A'}</b>
                      </span>
                    </li>
                    <li className="list_item">
                      <span className="index">Shift:</span>
                      <span className="value apurple">
                        <b>{shift || '-'}</b>
                      </span>
                    </li>
                    <li className="list_item">
                      <span className="index">Last Login:</span>
                      <span className="value apurple">
                        <b>{lastlogout || 'N/A'}</b>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="dropdown_group">
                  <div className="dropdown_group__title">Help</div>
                  <div className="dropdown_group__body">
                    <ul className="list_info list_sm">
                      <li className="list_item">
                        <i className="icon-mail" />
                        {/* <a mailto:href="#">techsupport@voxcrow.com</a> */}
                      </li>
                      <li className="list_item">
                        <i className="icon-phone" />
                        <span id="help_call">9802071665</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`sidebar ${Minimized ? "menu_bar__minimized" : ""}`}>
            <div className="menu-list">
              <ul>
                <li>
                  <Link to={"/dashboard"}>
                    <span className="menu-icon me-3">
                      <i className="fa fa-tachometer-alt"></i>
                    </span>
                    <span className="menu-label">Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ cursor: "pointer" }}
                    to={"/activities"}
                  >
                    <span className="menu-icon me-3">
                      <i
                        className="fa fa-history"
                        data-bs-toggle="tooltip"
                        title="Workspace"
                      ></i>
                    </span>
                    <span className="menu-label">Activities</span>
                  </Link>
                </li>

                <li
                  className={`contact-list menu_item__expandable ${isMenuItemExpanded ? "expanded" : ""
                    }`}
                >
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleMenuItemClick}
                  >
                    <span className="menu-icon me-3">
                      <i
                        className="fa fa-address-book"
                        data-bs-toggle="tooltip"
                        title="Contacts"
                      />
                    </span>
                    <span className="menu-label">
                      Contacts <i className="fa fa-angle-down" />
                    </span>
                  </Link>

                  <div className="menu menu_child">
                    <li className="menu_item px-0">
                      <Link
                        to={"/contact"}
                        className={`menu_Link ${pathname === "/contact" ? "active" : ""}`}
                        contentEditable="false"
                      >
                        <i className="fa fa-circle me-3" title="All Contacts" />
                        <span className="menu_Link__label">All contacts</span>
                      </Link>
                    </li>

                    <li className="menu_item px-0">
                      <Link
                        to={"/contactgroup"}
                        className={`menu_Link ${(pathname === "/contactgroup" || pathname.startsWith("/group/")) ? "active" : ""}`}
                        contentEditable="false"
                      >
                        <i className="fa fa-circle me-3" title="Contact groups" />
                        <span className="menu_Link__label">Contact Groups</span>
                      </Link>
                    </li>
                    {/* {role === USERS_ROLE.SUPERADMIN && ( */}
                      <li className="menu_item px-0">
                        <Link
                          to={"/globalContact"}
                          className={`menu_Link ${pathname === "/globalContact" ? "active" : ""}`}
                          contentEditable="false"
                        >
                          <i className="fa fa-circle me-3" title="Global Contacts" />
                          <span className="menu_Link__label">Global Contacts</span>
                        </Link>
                      </li>
                    {/* )} */}
                  </div>
                </li>
                <li>
                  <Link to={"/campaigns"}>
                    <span className="menu-icon me-3">
                      <i
                        className="fa fa-bullhorn"
                        data-bs-toggle="tooltip"
                        title="Campaigns"
                      ></i>
                    </span>
                    <span className="menu-label"> Campaigns </span>
                  </Link>
                </li>
                <li>
                  <Link to={"/ticket"}>
                    <span className="menu-icon me-3">
                      <i
                        className="fa fa-ticket-alt"
                        data-bs-toggle="tooltip"
                        title="Tickets"
                      ></i>
                    </span>
                    <span className="menu-label">Ticket </span>
                  </Link>
                </li>
                {role !== USERS_ROLE.AGENT && (
                  <li>
                    <Link to={"/stats"}>
                      <span className="menu-icon me-3">
                        <i
                          className="fa fa-chart-pie"
                          data-bs-toggle="tooltip"
                          title="Report"
                        ></i>
                      </span>
                      <span className="menu-label">Stat </span>
                    </Link>
                  </li>
                )}
                {role !== USERS_ROLE.AGENT && (
                  <li
                    className={`contact-list menu_item__expandable1 ${isReportExpanded ? "expanded" : ""
                      }`}
                  >
                    <Link onClick={handleReportClick} style={{ cursor: "pointer" }}>
                      <span className="menu-icon me-3">
                        <i
                          className="fa fa-chart-line"
                          data-bs-toggle="tooltip"
                          title="Report"
                        ></i>
                      </span>
                      <span className="menu-label">
                        Report <i className="fa fa-angle-down report" />
                      </span>
                    </Link>
                    <div className="menu menu_child">
                      <li className="menu_item px-0">
                        <Link
                          className={`menu_Link ${pathname === "/callreport" || pathname.startsWith("/incomingDetail/") || pathname.startsWith("/outboundDetail/") || pathname.startsWith("/queueDetail/")
                              ? "active"
                              : ""
                            }`}
                          contentEditable="false"
                          to={"/callreport/incoming"}
                        >
                          <i className="fa fa-circle me-3" title="Call Report" />
                          <span className="menu_Link__label">Call Report</span>
                        </Link>
                      </li>
                      <li className="menu_item px-0">
                        <Link
                          className={`menu_Link ${pathname === "/callDetailRecord"
                              ? "active"
                              : ""
                            }`}
                          contentEditable="false"
                          to={"/callDetailRecord"}
                        >
                          <i className="fa fa-circle me-3" title="Call Report" />
                          <span className="menu_Link__label">Call Detail Record</span>
                        </Link>
                      </li>
                      <li className="menu_item px-0">
                        <Link
                          className={`menu_Link ${pathname.startsWith("/agentReport")
                              ? "active"
                              : ""
                            }`}
                          contentEditable="false"
                          to={"/agentReport"}
                        >
                          <i className="fa fa-circle me-3" title="Agent Report" />
                          <span className="menu_Link__label">Agent Report</span>
                        </Link>
                      </li>
                      <li className="menu_item px-0">
                        <Link
                          className={`menu_Link ${pathname.startsWith("/teamReport")
                              ? "active"
                              : ""
                            }`}
                          contentEditable="false"
                          to={"/teamReport"}
                        >
                          <i className="fa fa-circle me-3" title="Team Report" />
                          <span className="menu_Link__label">Team Report</span>
                        </Link>
                      </li>
                      <li className="menu_item px-0">
                        <Link
                          to={"/distributionReport"}
                          className={`menu_Link ${pathname.startsWith("/distributionReport")
                              ? "active"
                              : ""
                            }`}
                          contentEditable="false"
                        >
                          <i
                            className="fa fa-circle me-3"
                            title="Distribution Report"
                          />
                          <span className="menu_Link__label">
                            Distribution Report
                          </span>
                        </Link>
                      </li>
                    </div>
                  </li>
                )}
                {role !== USERS_ROLE.AGENT && (
                  <li
                    className={`contact-list menu_item__expandable1 ${isWallboardExpanded ? "expanded" : ""
                      }`}
                  >
                    <Link to={"/wallBoard"} style={{ cursor: "pointer" }}>
                      <span className="menu-icon me-3">
                        <i
                          className="fa fa-chart-line"
                          data-bs-toggle="tooltip"
                          title="Report">
                        </i>
                      </span>
                      <span className="menu-label">
                        Wall Board
                      </span>
                    </Link>
                    {/* <div className="menu menu_child">
                    <li className="menu_item px-0">
                      <Link to={"/agentDetail"} className="menu_Link" contentEditable="false">
                        <i className="fa fa-circle me-3" title="Ticket Report" />
                        <span className="menu_Link__label">Agent report detail</span>
                      </Link>
                    </li>
                    <li className="menu_item px-0">
                      <Link to={"/getCallStatus"} className="menu_Link" contentEditable="false">
                        <i className="fa fa-circle me-3" title="Ticket Report" />
                        <span className="menu_Link__label">Get call status</span>
                      </Link>
                    </li>
                    <li className="menu_item px-0">
                      <Link to={"/additionalData"} className="menu_Link" contentEditable="false">
                        <i className="fa fa-circle me-3" title="Ticket Report" />
                        <span className="menu_Link__label">Additional Data</span>
                      </Link>
                    </li>
                    <li className="menu_item px-0">
                      <Link to={"/agentLiveCount"} className="menu_Link" contentEditable="false">
                        <i className="fa fa-circle me-3" title="Ticket Report" />
                        <span className="menu_Link__label">Agent Live Count</span>
                      </Link>
                    </li>
                  </div> */}
                  </li>
                )}
                <li>
                  <Link to={"/knowledgebase"}>
                    <span className="menu-icon me-3">
                      <i
                        className="fa fa-book"
                        data-bs-toggle="tooltip"
                        title="Knowledge base"
                      ></i>
                    </span>
                    <span className="menu-label">KB</span>
                  </Link>
                </li>
                {role !== USERS_ROLE.AGENT && (
                  <li>
                    <Link to={"/Realtime"}>
                      <span className="menu-icon me-3">
                        <i
                          className="fa fa-clock"
                          data-bs-toggle="tooltip"
                          title="Realtime"
                        ></i>
                      </span>
                      <span className="menu-label">Realtime </span>
                    </Link>
                  </li>
                )}
                {role !== USERS_ROLE.AGENT && (
                  <li>
                    <Link to={"/settings"}>
                      <span className="menu-icon me-3">
                        <i
                          className="fa fa-cog"
                          data-bs-toggle="tooltip"
                          title="Settings"
                        ></i>
                      </span>
                      <span className="menu-label">Settings </span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>
        {isSignOutModalVisible && (
          <SignOut userId={user.id} onClose={handleSignOutModalClose} />
        )}
        {isBreakModalVisible && (
          <UserBreak
            userId={user.id}
            onClose={() => setBreakModalVisible(false)}
            onSuccess={handleBreakSuccess}
          />
        )}
        {isChangePasswordModalVisible &&(
           <ChangePasswordModal 
              onClose={()=>setChangePasswordModalVisible(false)} 
              onSuccess={()=>setChangePasswordModalVisible(false)}
          />
        )}
      </div>
    </>
  );
}

export default Sidebar;
