import React from 'react';

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div
    role="alert"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <h2 style={{ fontSize: '24px', marginBottom: '16px' }}>Oops, something went wrong.</h2>
    <p style={{ fontSize: '16px', marginBottom: '24px', textAlign: 'center', maxWidth: '400px' }}>
      We're sorry for the inconvenience. Please refresh the page or try again later.
    </p>
    <button onClick={resetErrorBoundary} className='btn btn-primary'>
      Refresh Page
    </button>
  </div>
);

export default ErrorFallback;
