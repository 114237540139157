import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import config from "../config";

function UserBreak({ userId, onClose, onSuccess }) {
  const [breakReasons, setBreakReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBreakReasons = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/v1/breaks`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setBreakReasons(response.data.data);
      } else {
        console.error("Failed to fetch break reasons");
      }
    } catch (error) {
      console.error("Error fetching break reasons:", error);
    }
  };

  useEffect(() => {
    fetchBreakReasons();
  }, []);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleConfirmBreak = async () => {
    if (!selectedReason) {
      alert("Please select a reason for break.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/v1/users/${userId}/break`,
        {
            op: "set",
            reason: selectedReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        if (onSuccess) {
          onSuccess();
        }
        toast.success("Break successful!", {containerId: 'sidebarToast'});
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error while taking break: ${error.response?.data?.message || error.message}`, {containerId: 'sidebarToast'});
      } else {
        toast.error("Error while taking break: " + error.message, {containerId: 'sidebarToast'});
      }
      console.error("Error taking break:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="modal fade show"
      id="signOutModal"
      tabIndex={-1}
      aria-labelledby="signOutModalLabel"
      aria-hidden="true"
      style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="signOutModalLabel">
              Select Reason for Break
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="logoutReason">Break Reason</label>
              <select
                id="logoutReason"
                className="form-control"
                value={selectedReason || ""}
                onChange={handleReasonChange}
              >
                <option value="" disabled>
                  Select a reason
                </option>
                {breakReasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirmBreak}
              disabled={isLoading}
            >
              {isLoading ? "Taking Break..." : "Confirm Break"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBreak;
