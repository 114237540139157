import React, { useState, useEffect } from "react";
import "../assets/css/Campaigns.css";
import "../assets/css/Vox.css";
import config from "../config";
import { useUser } from "../context/UserContext";
import { READY_STATUS } from "../utils/common/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EventModal() {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [updateContactData, setUpdateContactData] = useState({});
  const [updateContactformErrors, setupdateContactformErrors] = useState({});
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const agent_id = localStorage.getItem('agent_id');

  useEffect(() => {
    const eventSource = fetchEvents();

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };

  }, []);

  const fetchNotes = async (contactId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/v1/reports/${contactId}/notes`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();

      if (result.success) {
        setNotes(result.data);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const getContactInfoByNumber = async (number) => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await fetch(`${config.apiBaseUrl}/api/v1/contacts/${number}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();

      if (result.success) {
        const data = result.data;
        fetchNotes(data?.id);
        setUpdateContactData({
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          contact_primary: data?.contact_primary,
          company: data?.company || "",
          street: data?.street || "",
          city: data?.city || "",
          state: data?.state || "",
          country: data?.country || "",
          remarks: "",
          remarksTitle: "",
          contact_id: data?.id,
        });
      }
    } catch (error) {
      console.error("Error fetching scripts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const token = localStorage.getItem("token");
    const eventSource = new EventSource(`${config.inboundCallApiBaseUrl}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  
    eventSource.onmessage = (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        console.error('Invalid response received:', error);
        return;
      }
      if (data) {

        if(data.data?.agentId !== agent_id) {
          return;
        }

        const userReadyStatus = (parseInt(localStorage.getItem("isReadyForCall"), 10) ?? 0);

        if (userReadyStatus !== READY_STATUS.ACTIVE) return;

        if(data.data?.callerNumber) {
          getContactInfoByNumber(data.data?.callerNumber);
        }
        setUpdateContactData({
          first_name: data.data.contact?.first_name || "",
          last_name: data.data.contact?.last_name || "",
          contact_primary: data.data.callerNumber,
          company: data.data.contact?.company || "",
          street: data.data.contact?.street || "",
          city: data.data.contact?.city || "",
          state: data.data.contact?.state || "",
          country: data.data.contact?.country || "",
          remarks: "",
          remarksTitle: "",
          contact_id: data.data.contact?.id,
        });
  
        setNotes(data.data?.notes);
        setShowModal(true);
        setLoading(false);

      } else {
        setShowModal(false);
      }
    };
  
    eventSource.onerror = (error) => {
      console.error("Error receiving event data:", error);
      eventSource.close();
      setLoading(false);
      setShowModal(false);
    };

    return eventSource;

  };

  function getPlainText(remarks) {
    try {
      const parsedRemarks = JSON.parse(remarks);

      if (parsedRemarks.blocks && Array.isArray(parsedRemarks.blocks)) {
        return parsedRemarks.blocks.map((block) => block.text).join(" ");
      }
    } catch (e) {}

    return remarks;
  }

  const changeContactValue = (e) => {
    const { name, value } = e.target;
    setUpdateContactData({ ...updateContactData, [name]: value });
    setupdateContactformErrors({ ...updateContactformErrors, [name]: "" });
  };

  const validateUpdateContactFields = () => {
    const errors = {};
    // if (!updateContactData?.first_name) {
    //   errors.first_name = "First name is required";
    // }
    // if (!updateContactData?.last_name) {
    //   errors.last_name = "Last name is required";
    // }
    if (!updateContactData?.contact_primary) {
      errors.contact_primary = "Contact number is required";
    }
    // if (!updateContactData?.remarks) {
    //   errors.remarks = "Remarks is required";
    // }
    // if (!updateContactData?.remarksTitle) {
    //   errors.remarksTitle = "Remarks title is required";
    // }
    // if (!updateContactData?.company) {
    //   errors.company = "Company is required";
    // }
    // if (!updateContactData?.state) {
    //   errors.state = "State is required";
    // }
    // if (!updateContactData?.street) {
    //   errors.street = "Street is required";
    // }
    // if (!updateContactData?.city) {
    //   errors.city = "City is required";
    // }
    // if (!updateContactData?.country) {
    //   errors.country = "Country is required";
    // }
    setupdateContactformErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateContact = async (e, status = false) => {
    if (loading) {
      return;
    }

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!validateUpdateContactFields()) {
      return;
    }

    const contactNumberList = [
      {
        value: updateContactData.contact_primary,
        type: "1",
      },
    ];

    const dataToSend = {
      first_name: updateContactData.first_name,
      last_name: updateContactData.last_name,
      contact_number: contactNumberList,
      company: updateContactData.company,
      street: updateContactData.street,
      city: updateContactData.city,
      state: updateContactData.state,
      country: updateContactData.country,
      remarks: updateContactData.remarks,
      title: updateContactData.remarksTitle,
      created_by: user.id
    };

    // Filter out any keys with value undefined, null, or empty string
    const cleanedData = Object.entries(dataToSend).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    const token = localStorage.getItem("token");

    setLoading(true);

    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/v1/contacts/${updateContactData.contact_primary}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(cleanedData),
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success(data.message || "Contact updated successfully");
        setShowModal(false);
        // setUpdateContactData({});
        setupdateContactformErrors({});
      } else {
        toast.error(data.message || "Failed to updated contact");
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      toast.error("Error updating contact");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showModal && (
        <div
          className="modal-overlay"
          style={{
            zIndex: 999999,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflowY: "auto",
          }}
        >
          <ToastContainer style={{ marginTop: "80px" }} />
          <div className="modal-xl modal-dialog modal-dialog-centered custom-modal-width">
            <div
              className="modal-content"
              style={{ backgroundColor: "#fcfcfc" }}
            >
              <div
                className="modal-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                <h5 className="modal-title" style={{ margin: 0 }}>
                  Contact Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "1.5rem",
                    lineHeight: "1",
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="lyt_box mt-4">
                  <div className="row g-4">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="block-widget">
                        <h6>Form</h6>
                        <form onSubmit={(e) => handleUpdateContact(e, false)}>
                          <div className="form_group">
                            <label className="form-label">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              value={updateContactData.first_name || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.first_name && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.first_name}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              value={updateContactData.last_name || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.last_name && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.last_name}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">
                              {" "}
                              Contact Number*
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="contact_primary"
                              value={updateContactData.contact_primary || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.contact_primary && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.contact_primary}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Company</label>
                            <input
                              type="text"
                              className="form-control"
                              name="company"
                              value={updateContactData.company || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.company && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.company}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Street</label>
                            <input
                              type="text"
                              className="form-control"
                              name="street"
                              value={updateContactData.street || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.street && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.street}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              value={updateContactData.city || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.city && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.city}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">State</label>
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              value={updateContactData.state || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.state && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.state}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              name="country"
                              value={updateContactData.country || ""}
                              onChange={changeContactValue}
                            />
                            {updateContactformErrors.country && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.country}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Remarks Title</label>
                            <input
                              className="form-control"
                              name="remarksTitle"
                              value={updateContactData.remarksTitle || ""}
                              onChange={changeContactValue}
                              maxLength={250}
                            />
                            {updateContactformErrors.remarksTitle && (
                              <div className="error">
                                <span className="text-danger">{updateContactformErrors.remarksTitle}</span>
                              </div>
                            )}
                          </div>
                          <div className="form_group">
                            <label className="form-label">Remarks</label>
                            <textarea
                              className="form-control"
                              name="remarks"
                              value={updateContactData.remarks || ""}
                              onChange={changeContactValue}
                              rows={4}
                              style={{ height: "70px" }}
                            />
                            {updateContactformErrors.remarks && (
                              <div className="error">
                                <span className="text-danger">
                                  {updateContactformErrors.remarks}
                                </span>
                              </div>
                            )}
                          </div>
                          <div class="form_group">
                              <label className="form-label">Source Type</label>
                              <select id="source-type">
                                  <option value="" disabled selected>Select source type</option>
                                  <option value="web">Web</option>
                                  <option value="phone">Phone</option>
                                  <option value="email">Email</option>
                              </select>
                          </div>
                          <div class="form_group">
                              <label for="query-type">Query Type</label>
                              <select id="query-type">
                                  <option value="" disabled selected>Select query type</option>
                                  <option value="general">General</option>
                                  <option value="technical">Technical</option>
                                  <option value="billing">Billing</option>
                              </select>
                          </div>
                          <div class="form_group">
                              <label for="outcome">Outcome</label>
                              <select id="outcome">
                                  <option value="" disabled selected>Select outcome</option>
                                  <option value="resolved">Resolved</option>
                                  <option value="pending">Pending</option>
                                  <option value="escalated">Escalated</option>
                              </select>
                          </div>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="block-widget">
                        <div style={{ overflowY: "auto", width: "100%" }}>
                          <table
                            className="table table-striped"
                            style={{ width: "100%", tableLayout: "fixed" }}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "33%" }}>Agent name</th>
                                <th style={{ width: "33%" }}>Remark</th>
                                <th style={{ width: "33%" }}>Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(notes) && notes.length > 0 ? (
                                notes.map((note, index) => (
                                  <tr key={note.id}>
                                    <td
                                      style={{
                                        width: "33%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {note["UserDetail.Agents.agent_name"] ||
                                        ""}
                                    </td>
                                    <td
                                      style={{
                                        width: "33%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {getPlainText(note.remarks)}
                                    </td>
                                    <td
                                      style={{
                                        width: "33%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {note.created_at}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="3" className="text-center">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EventModal;
