export const USERS_ROLE = {
    SUPERVISOR: 'SUPERVISOR',
    AGENT: 'AGENT',
    SUPERADMIN: 'SUPERADMIN'
}

export const LOGIN_STATUS ={
    ACTIVE: 1,
    INACTIVE: 0
}

export const READY_STATUS ={
    ACTIVE: 1,
    INACTIVE: 0
}
