import React, { useState, useEffect } from "react";
import config from "../config";
import { useUser } from "../context/UserContext";
import { READY_STATUS } from "../utils/common/constants";
import ContactCallModal from "./contactCallModal";

function OutboundCallModal() {
    const { user } = useUser();
    const agent_id = parseInt(localStorage.getItem('agent_id'));
    const [loading, setLoading] = useState(false);
    const [updateContactData, setUpdateContactData] = useState({});
    const [showContactCallModal, setShowContactCallModal] = useState(false);

    useEffect(() => {
        const eventSource = fetchEvents();
    
        return () => {
          if (eventSource) {
            eventSource.close();
          }
        };
    
    }, []);

    const fetchEvents = () => {
        if (loading) {
          return;
        }
        setLoading(true);
        const token = localStorage.getItem("token");
        const eventSource = new EventSource(`${config.outboundCallApiBaseUrl}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
        eventSource.onmessage = (event) => {
          let data;
          try {
            data = JSON.parse(event.data);
          } catch (error) {
            console.error('Invalid response received:', error);
            return;
          }
          if (data) {
    
            if(data.data?.agentId !== agent_id) {
              return;
            }

            const userReadyStatus = (parseInt(localStorage.getItem("isReadyForCall"), 10) ?? 0);
    
            if (userReadyStatus !== READY_STATUS.ACTIVE) return;

            if(data.data?.callerNumber) {
              getContactInfoByNumber(data.data?.callerNumber);
            }
            
            setLoading(false);
    
          } else {
            setShowContactCallModal(false);
          }
        };
      
        eventSource.onerror = (error) => {
          console.error("Error receiving event data:", error);
          eventSource.close();
          setLoading(false);
          setShowContactCallModal(false);
        };
    
        return eventSource;
    
    };

    const getContactInfoByNumber = async (number) => {
        const token = localStorage.getItem("token");
        try {
          setLoading(true);
          const response = await fetch(`${config.apiBaseUrl}/api/v1/contacts/${number}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const result = await response.json();
    
          if (result.success) {
            const data = result.data;
            setUpdateContactData({
                contact: {
                    first_name: data?.first_name || "",
                    last_name: data?.last_name || "",
                    contact_primary: data?.contact_primary,
                    company: data?.company || "",
                    street: data?.street || "",
                    city: data?.city || "",
                    state: data?.state || "",
                    country: data?.country || "",
                    remarks: "",
                    remarksTitle: "",
                    contact_id: data?.id,
                    id: data?.id
                }
            });
          }
        } catch (error) {
          console.error("Error fetching scripts:", error);
        } finally {
          setLoading(false);
          setShowContactCallModal(true);
        }
    };

    const handleCloseContactCallModal = () => setShowContactCallModal(false);

    return(
        <>
        {showContactCallModal && 
          <ContactCallModal onClose={handleCloseContactCallModal} data={updateContactData} />
        }
        </>
    );

}

export default OutboundCallModal;