import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import config from "../config";
import { useUser } from "../context/UserContext";
// import "../assets/css/SignOut.css";

function SignOut({ userId, onClose, onSuccess }) {
  const [logoutReasons, setLogoutReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchLogoutReasons = async () => {
      const token = localStorage.getItem("token"); // Assuming JWT is stored in localStorage with key 'jwt'

      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/api/v1/logouts`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the JWT in the Authorization header
            },
          }
        );
        if (response.data.success) {
          setLogoutReasons(response.data.data);
        } else {
          console.error("Failed to fetch logout reasons");
        }
      } catch (error) {
        console.error("Error fetching logout reasons:", error);
      }
    };

    fetchLogoutReasons();
  }, []);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleConfirmLogout = async () => {
    if (!selectedReason) {
      alert("Please select a reason for logging out.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token"); // Get the JWT

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/v1/users/${userId}/logout`,
        {
          logout_reason: selectedReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT in the Authorization header
          },
        }
      );

      if (response.data.success) {
        if (onSuccess) {
          onSuccess();
        }
      }

      if(user.id === userId) {  
        localStorage.clear();
        navigate("/login");
      } else {
        toast.success("Logout successful!");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error while logging out: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error("Error while logging out: " + error.message);
      }
      console.error("Error during logout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="modal fade show"
      id="signOutModal"
      tabIndex={-1}
      aria-labelledby="signOutModalLabel"
      aria-hidden="true"
      style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="signOutModalLabel">
              Select Reason for Logging Out
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="logoutReason">Logout Reason</label>
              <select
                id="logoutReason"
                className="form-control"
                value={selectedReason || ""}
                onChange={handleReasonChange}
              >
                <option value="" disabled>
                  Select a reason
                </option>
                {logoutReasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirmLogout}
              disabled={isLoading}
            >
              {isLoading ? "Logging out..." : "Confirm Logout"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignOut;
