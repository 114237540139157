import React from "react";
import "../assets/css/ScriptDetail.css";

function ScriptDetail({ scriptData }) {
    return (
        <div className="script-detail-container container">
            <h2 className="script-name text-center">{scriptData?.script_name}</h2>

            <div className="row mt-4">
                <div className="col-sm-12">
                    <div className="script-box description-box">
                        <h3>Description</h3>
                        <p>{scriptData?.short_desc}</p>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="script-box text-box">
                        <h3>Script Text</h3>
                        <p>{scriptData?.script_text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScriptDetail;
